import React, { FC, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _, { isEmpty } from 'lodash';
import {
    Col,
    Row,
    CardBody,
    Card,
    Container, Button,
    CardText
} from 'reactstrap';
import { fetchCandidateList, setCandidateIdentifier, fetchCandidateFolderLocations, fetchEvidenceLevelFiles, toggleAlertShowStatus } from '../../redux-building-blocks/actions';
import {
    toggleLoaderShowStatus
} from '../../redux-building-blocks/actions';
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import CandidateEvidenceDocuments from './candidate-evidence-documents';

const CandidateEvidence: FC = (props: any): JSX.Element => {
    const { candidateId }: any = useParams();
    const history = useHistory();
    let { url } = useRouteMatch();
    const evidenceSections = url.split('/');
    evidenceSections.pop();
    // url.split('/').join('/');
    url = evidenceSections.join('/');
    const [candidate, setcandidate] = useState({} as any);
    const [disablePrevious, setDisablePrevious] = useState(false);
    const [disableNext, setDisableNext] = useState(false);
    const [candidateDetails, setCandidateDetails] = useState({
        candidateName: '',
        candidateNumber: '',
        candidateUserNumber:'',
        setNumber: 0,
        evidenceList: [],
        isDuplicate: false
    });

    useEffect(() => {
        if(_.isEqual(props.candidateIdentifier, parseInt(candidateId)) &&
             !_.isEmpty(props.selectedSyllabus)) {
            const selectedcandidate = props.candidateList?.find((candidate: any) =>
            candidate.candidateNumber === props.candidateIdentifier
            ),
            candidatePayload = {
                candidateId: props.candidateIdentifier,
                syllabusId: props.selectedSyllabus.syllabusNumber
            },
            index = props.candidateList?.findIndex((each: any) => each.candidateNumber === props.candidateIdentifier);
            setDisablePrevious(!index);
            setDisableNext(!!(index === props.candidateList?.length - 1))
            setcandidate(selectedcandidate);
            if (candidatePayload.candidateId && candidatePayload.syllabusId) {
                props.fetchCandidateFolderLocations(candidatePayload);
            }
        } else {
            props.setCandidateIdentifier(parseInt(candidateId));
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.candidateIdentifier, props.selectedSyllabus]);

    useEffect(() => {
        // if (!_.isEmpty(props.selectedCandidateWithEvidence)) {
            setCandidateDetails(props.selectedCandidateWithEvidence)
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedCandidateWithEvidence])


    const prevCandidate = () => {
        const index = props.candidateList?.findIndex((obj: any) => obj.candidateNumber === candidate.candidateNumber)
        if (index <= props.totalCandidates - 1) {

            props.setCandidateIdentifier(props.candidateList[index - 1].candidateNumber);
            history.push({
                pathname: `${url}/${props.candidateList[index - 1].candidateNumber}`,
                state: {
                    fromPath: props.location.pathname
                }
            });
        }

    }

    const nextCandidate = () => {
        const index = props.candidateList?.findIndex((obj: any) => obj.candidateNumber === candidate.candidateNumber)
        if (index <= props.totalCandidates - 1) {

            props.setCandidateIdentifier(props.candidateList[index + 1].candidateNumber);
            history.push({
                pathname: `${url}/${props.candidateList[index + 1].candidateNumber}`,
                state: {
                    fromPath: props.location.pathname
                }
            });
        }
    }
    
    return (
        <Fragment>
            <Container fluid className='container-wrapper'>
                <div className='wrapper'>
                    <div>
                        <p className='theme-label centre-head'>Centre {props.centreDetails}-{props.selectedSyllabus.syllabusName}</p>
                        <p className='syllabus-subtext'><span className='igcse-label'>{props.selectedSyllabus.syllabusNumber}</span> | <span className='candidate-number'>{props.totalCandidates} candidates</span></p>
                    </div>
                    <div>
                        <br></br>
                        <div className='student-instruction'>
                        Add a grade for this candidate and upload the necessary files (maximum 10 files). 
                        <br/> Files must be uploaded one by one. 
                   </div>
                    </div>
                </div>

            </Container>
            <Container className="candidate-list-container mb-35">
                <Row className="align-items-center">
                    <div className="mr-10">
                        <Button disabled={disablePrevious} className="prev-candidate" onClick={() => prevCandidate()}>
                            <span className="btn-status-text">Previous candidate</span>
                        </Button>
                    </div>
                    <div>
                        <Button disabled={disableNext} className="prev-candidate ml-1" onClick={() => nextCandidate()}>
                            <span className="btn-status-text">Next candidate</span>
                        </Button>
                    </div>
                </Row>
                <br></br>
                {(!_.isEmpty(candidateDetails.candidateName) ||
                !_.isEmpty(candidateDetails.candidateUserNumber)) && <Card className="borderTop mt-1">
                    <CardBody className="card-body pxy-2">                    
                            <Row
                                className="align-items-center">
                                <Col md={8}>
                                    <div className="h5 font-weight-bold centerName">{candidateDetails.candidateName}</div>
                                    <div className="font-weight-500 grayText">{candidateDetails.candidateUserNumber}</div>
                                </Col>
                            </Row>                     
                    </CardBody>
                </Card>}
                {(!candidateDetails.evidenceList || candidateDetails.evidenceList.length <=0) ? (<Card body className="text-center rounded">     
                 <CardText>Evidence Folders/Files can't be fetched</CardText>
                 </Card>) : (
                    candidateDetails.evidenceList.map((eachItem: any, index: number) => {
                        return (<CandidateEvidenceDocuments
                            key={`CandidateEvidence-${index}`}
                            eachEvidenceItem={eachItem}
                            candidateSetNumber = {candidateDetails.setNumber}
                            isDuplicate ={candidateDetails.isDuplicate}
                            index={index} />)
                    })
                 )
                }
            </Container>
        </Fragment>
    )
}

const mapStateToProps = (state: any) => {
    return {
        totalCandidates: state.setContextData.selectedSyllabus?.candidateDetails?.length,
        candidateList: state.setContextData.selectedSyllabus?.candidateDetails,
        candidateIdentifier: state.setContextData?.candidateIdentifier,
        selectedSyllabus: state.setContextData?.selectedSyllabus,
        centreDetails: state.setContextData?.currentCentreId,
        selectedCandidateWithEvidence: state.setContextData?.selectedCandidateWithEvidence
    }
}

const mapDispatchToProps = {
    fetchCandidateList,
    setCandidateIdentifier,
    fetchCandidateFolderLocations,
    fetchEvidenceLevelFiles,
    toggleLoaderShowStatus,
    toggleAlertShowStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateEvidence);

