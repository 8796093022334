import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
    Col, Row
} from 'reactstrap'
import './uploaded-file-list.css';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Constant } from '../../../config/constant';

interface UploadedFileListProps {
    fileList: Array<string>;
    fileListHandler: any;
    fileDeleteHandlerAPI: any;
    errorCallback: any;
    isSubmited: boolean;
    fileListWithStatus?: any;
    selectedSyllabusStatus?: string;
}

const UploadedFileListComp: FC<UploadedFileListProps> = ({
    isSubmited,
    fileList,
    fileListHandler,
    fileDeleteHandlerAPI,
    fileListWithStatus,
    selectedSyllabusStatus,
    errorCallback }): JSX.Element => {

    const fileDeleteHandler = async (deleteFile: string) => {
        try {
            const confirm = window.confirm(
                `Do you really want to delete ${deleteFile}?`
            )
            if (confirm) {
                await fileDeleteHandlerAPI(deleteFile);
                fileListHandler(_.reject(fileList, (eachFileName) => eachFileName === deleteFile), "DELETE", deleteFile);
            }
        } catch (error) {
            if (errorCallback) {
                errorCallback(deleteFile);
            }
        }

    }

    const checkReadOnlyStatus = (fileName: any, index: number) => {
        return fileListWithStatus.filter((each: any) => each['file-name'] === fileName).map((obj: any) => obj['read-only'])[0];
    }

    const fileListHTML = fileList.map((file, index) =>
        <Row className="p-2" key={`${file}_${+ new Date()}_${index}`}>
            <Col>
                <span className="pr-3">{file}</span>
            </Col>
            <Col className="text-right">
                {(selectedSyllabusStatus === Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.FIRST_SAMPLE_FURTHER_REQUESTED ||
                    selectedSyllabusStatus === Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_REQUESTED)
                    ? (!isSubmited && !checkReadOnlyStatus(file, index)) && <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer' }} onClick={(e) => fileDeleteHandler(file)} />
                    : !isSubmited && <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer' }} onClick={(e) => fileDeleteHandler(file)} />
                }
                {/* {!isSubmited && <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer' }} onClick={(e) => fileDeleteHandler(file)} />} */}
            </Col>
        </Row>
    );
    return (
        <React.Fragment>
            {fileList.length > 0 && <div className="w-50 pt-3 grayText">
                {fileListHTML}
            </div>}
        </React.Fragment>
    )
}


export default connect(
    null,
    null
)(UploadedFileListComp)
