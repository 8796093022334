import React, { FC, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import _ from 'lodash';
import './uploader.css';
import UploadedFileList from './../UploadedFileList/uploaded-file-list';

import {
    toggleAlertShowStatus
} from './../../../redux-building-blocks/actions';

import PreSignedUrlUploadHelper from './../../../helper/pre-signed-url-upload-helper';

import { getCurrentSyllabusStayus } from './../../../redux-building-blocks/selectors/util-selector'
import { Constant } from '../../../config/constant';

interface UploaderProps {
    noOfMaxFile?: number;
    buttonText?: string;
    isShowUploadedFileList?: boolean;
    successCallback?: any;
    errorCallback?: any;
    fileList?: Array<string>;
    toggleAlertShowStatus?: any;
    fileFormatAndSizeSupported?: Array<any>;
    getPreSignedUploadURl: any;
    fileDeleteHandler: any;
    index?: any;
    isSubmited?: boolean;
    fileListWithStatus?: any;
    selectedSyllabusStatus?: string;
    selectedCandidateSetNumber?: any;
    sourceLocation?: string;


}

const Uploader: FC<UploaderProps> = ({
    isSubmited,
    toggleAlertShowStatus,
    successCallback,
    getPreSignedUploadURl,
    fileDeleteHandler,
    noOfMaxFile = 10,
    buttonText = 'Upload a file',
    isShowUploadedFileList = true,
    fileList = [],
    fileListWithStatus = [],
    fileFormatAndSizeSupported = [{ name: '.pdf', size: null }], //pass size in byte and if null it will not be checked
    errorCallback = null,
    index = 1,
    selectedSyllabusStatus,
    selectedCandidateSetNumber = 0,
    sourceLocation = ''
}): JSX.Element => {

    const [localButtonText, setLocalButtonText] = useState(buttonText);
    let currentFileList = [...fileList];

    useEffect(() => {
        if (noOfMaxFile > 1) {
            setButtonText(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileList]);

    const changeHandler = async (event: any) => {
        const uploadableFile: any = event.target.files[0];
        if (checkTotalNumberOfFile()) {
            if (!checkFileFormatAndSize(uploadableFile)) {
                try {
                    const { url, fields } = await getPreSignedUploadURl(uploadableFile.name);
                    await PreSignedUrlUploadHelper.uploadFile(url, fields, uploadableFile);
                    if (noOfMaxFile > 1) {
                        setButtonText();
                    }
                    currentFileList = [...currentFileList, uploadableFile.name];
                    successCallback(currentFileList, 'UPLOAD', uploadableFile.name);
                } catch (error) {
                    if (errorCallback) {
                        errorCallback(uploadableFile.name, 'UPLOAD');
                    }
                }
            }
        } else {
            toggleAlertShowStatus({
                isShowAlert: true,
                alertColor: 'danger',
                alertMessage: `You can't upload more than ${noOfMaxFile} files`
            })
        }

        event.target.value = '';
    };

    const checkTotalNumberOfFile = () => {
        return _.sum([currentFileList.length, 1]) <= noOfMaxFile;
    }

    const setButtonText = (newFileCout = 1) => {
        const totalNoOfFile = _.sum([currentFileList.length, newFileCout]);
        buttonText = (totalNoOfFile > 0) ? 'Add additional files' : 'Upload a file';
        setLocalButtonText(buttonText);
    }

    const checkFileFormatAndSize = (file: any) => {
        const fileFormatSupported = _.map(fileFormatAndSizeSupported, 'name');
        let error = (new RegExp('(' + fileFormatSupported.join('|').replace(/\./g, '\\.') + ')$')).test(_.toLower(file.name)) ? false : true;
        if (error) {
            toggleAlertShowStatus({
                isShowAlert: true,
                alertColor: 'danger',
                alertMessage: `You can't upload ${file.name} as format is not supported`
            })
        } else if (checkFileSize(file) || sameNameFileExist(file)) {
            error = true;
        }
        return error;
    }

    const checkFileSize = (file: any) => {
        const fileAllowedConfig = _.find(fileFormatAndSizeSupported, (o) => _.lowerCase(o.name) === _.lowerCase(file.name.split('.').pop()));
        const error = (file.size && fileAllowedConfig.size && file.size > fileAllowedConfig.size) ? true : false;
        if (error) {
            toggleAlertShowStatus({
                isShowAlert: true,
                alertColor: 'danger',
                alertMessage: `You can't upload ${file.name} due to file size restriction`
            })
        }
        return error;
    }

    const sameNameFileExist = (file: any) => {
        const error = _.find(currentFileList, (eachFileName) => _.lowerCase(eachFileName) === _.lowerCase(file.name));
        if (error) {
            toggleAlertShowStatus({
                isShowAlert: true,
                alertColor: 'danger',
                alertMessage: `You can't upload ${file.name} as file with same name is already there`
            })
        }
        return error;
    }

    const checkForSecondSample = (status: any) => {
        return status?.trim() === Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_REQUESTED.trim()
    }

    return (
        <React.Fragment>
            {/* <label htmlFor={`file-upload-${index}`} className={`custom-file-upload font-weight-bold py-2 px-3
            ${(isSubmited || currentFileList.length >= noOfMaxFile || (selectedSyllabusStatus?.trim() === Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_REQUESTED.trim() && selectedCandidateSetNumber !== 2)) ? 'custom-file-upload-disabled' : ''}`}> */}
            <label htmlFor={`file-upload-${index}`} className={`custom-file-upload font-weight-bold py-2 px-3
            ${(isSubmited || currentFileList.length >= noOfMaxFile || (checkForSecondSample(selectedSyllabusStatus) && selectedCandidateSetNumber === 1) || (checkForSecondSample(selectedSyllabusStatus) && sourceLocation === 'additional')) ? 'custom-file-upload-disabled' : ''}`}>
                <FontAwesomeIcon icon={faCloudUploadAlt} /> {localButtonText}
            </label>

            <input id={`file-upload-${index}`} type="file" name={`file-${index}[]`} style={{ display: "none" }}
                onChange={changeHandler} disabled={isSubmited || currentFileList.length >= noOfMaxFile || (checkForSecondSample(selectedSyllabusStatus) && selectedCandidateSetNumber === 1) || (checkForSecondSample(selectedSyllabusStatus) && sourceLocation === 'additional')} />
            {isShowUploadedFileList && <UploadedFileList fileList={currentFileList}
                fileListWithStatus={fileListWithStatus}
                selectedSyllabusStatus={selectedSyllabusStatus}
                fileListHandler={successCallback} fileDeleteHandlerAPI={fileDeleteHandler}
                errorCallback={errorCallback} isSubmited={isSubmited as boolean}
            />}
        </React.Fragment>
    )
}

const mapStateToProps = (state: any) => {
    return {
        isSubmited: getCurrentSyllabusStayus(state),
        selectedSyllabusStatus: state.setContextData.selectedSyllabus.syllabusStatus,
        selectedCandidateSetNumber: state.setContextData?.selectedCandidateWithEvidence?.setNumber
    }
}

const mapDispatchToProps = {
    toggleAlertShowStatus
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Uploader)
