import {
    GET_CONTEXT_DATA_SUCCESS,
    GET_CANDIDATE_LIST,
    SET_CANDIDATE_IDENTIFIER,
    SET_CURRENT_CENTRE_ID,
    SET_CURRENT_USER_ID,
    TOGGLE_ALERT,
    FETCH_CANDIDATE_DOCUMENTS_SUCCESS,
    UPDATE_RATIONAL_DOCUMENT,
    FETCH_ADDITIONAL_DOCUMENT_SUCCESS,
    UPDATE_ADDITIONAL_DOCUMENT,
    UPDATE_LOADER_SHOW_STATUS,
    FETCH_CANDIDATE_FOLDERS_SUCCESS,
    UPDATE_CANDIDATE_GRADE_SUCCESS,
    UPDATE_CANDIDATE_EVIDENCE_UPLOADED_DELETED_FILE,
    EVIDENCE_SUBMISSION_SUCCESS,
    FETCH_EVIDENCE_FILES_FAILURE

} from '../action-types';
import { IInitialState } from './model';
import _ from 'lodash';
import { Constant } from '../../config/constant';


const initialState: IInitialState = {
    currentUserId: '',
    currentCentreId: '',
    syllabusList: [],
    selectedSyllabus: {},
    selectedCandidateWithEvidence: {},
    candidateIdentifier: "",
    candidateGenericDocuments: {},
    candidateAdditionalDocuments: {},
    additionalDocReadOnlyStatus: [],
    alertData: {
        isShowAlert: false,
        alertColor: '',
        alertMessage: ''
    },
    isShowLoader: false
};

const generateNewSyllabus = (elem: any, syllabusStatusres = []) => {
    return {
        syllabusNumber: elem['centre']['syllabus-no'],
        syllabusName: elem['centre']['syllabus-name'],
        syllabusStatus: _.get(_.find(syllabusStatusres, ['syllabus', elem['centre']['syllabus-no']]), 'message', ''),
        candidateDetails: [{
            candidateNumber: elem['centre']['candidate-no'],
            candidateUserNumber: elem['centre']['candidate-user-number'],
            candidateName: elem['centre']['candidate-name'],
            setNumber: elem['centre']['set-number'],
            gradeSet: elem['centre']['syllabus-gradeset']?.split(',')
        }]
    }
}

const restructureSyllabusDetails = (payload: any, syllabusStatusres: any) => {
    let arr: any = [], newObj;
    return payload.reduce((_acc: any, _elem: any) => {
        if (arr.length) {
            const currentIndex = arr.findIndex((each: any) => each.syllabusNumber === _elem['centre']['syllabus-no']);
            if (currentIndex === -1) {
                newObj = generateNewSyllabus(_elem, syllabusStatusres);
                arr = [...arr, newObj];
            } else {
                arr[currentIndex].candidateDetails.push({
                    candidateNumber: _elem['centre']['candidate-no'],
                    candidateUserNumber: _elem['centre']['candidate-user-number'],
                    candidateName: _elem['centre']['candidate-name'],
                    setNumber: _elem['centre']['set-number'],
                    gradeSet: _elem['centre']['syllabus-gradeset']?.split(',')
                });
            }

        } else {
            newObj = generateNewSyllabus(_elem, syllabusStatusres);
            arr = [...arr, newObj];
        }
        return arr;
    }, arr)
}

const appendEvidenceData = (selectedCandidate: any, response: any) => {
    // response.forEach((elem: any) => {
    //     const index = selectedCandidate.evidenceList.findIndex((each: any) => each['candidate-evidence-file-location'] === elem['candidate-evidence']['candidate-evidence-file-location'])
    //     if (index !== -1) {
    //         selectedCandidate.evidenceList[index]['candidate-evidence-grade'] = elem['candidate-evidence']['candidate-evidence-grade']
    //         selectedCandidate.evidenceList[index]['candidate-evidence-id'] = elem['candidate-evidence']['candidate-evidence-id']
    //     }
    // })
    return selectedCandidate;
}

const updateCandidateEvidence = (currentState: any, actionPayload: any) => {
    const { selectedCandidateWithEvidence } = currentState;
    const { evidenceList } = selectedCandidateWithEvidence;
    evidenceList[actionPayload.evidenceIndex].uploadedProps.uploadedFiles = actionPayload.fileNames;

    return { ...selectedCandidateWithEvidence };
}

const updateSyllabusStatus = (selectedSyllabus: any, status: string) => {
    switch (selectedSyllabus.syllabusStatus) {
        case Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.FIRST_SAMPLE_REQUESTED:
            selectedSyllabus.syllabusStatus = Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.FIRST_SAMPLE_SUBMITTED;
            return selectedSyllabus;;

        case Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_REQUESTED:
            selectedSyllabus.syllabusStatus = Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_SUBMITTED;
            return selectedSyllabus;;

        case Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.FIRST_SAMPLE_FURTHER_REQUESTED:
            selectedSyllabus.syllabusStatus = Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.FIRST_SAMPLE_FURTHER_SUBMITTED;
            return selectedSyllabus;;

        case Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_FURTHER_REQUESTED:
            selectedSyllabus.syllabusStatus = Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_FURTHER_SUBMITTED;
            return selectedSyllabus;;

        default: return selectedSyllabus;

    }

}

const filterDuplicateCandidatesForSecondEvidence = (syllabusList: any) => {
    let newCandidateList: any[] = [];
    return syllabusList.map((_eachSyllabus: any) => {
        if (_eachSyllabus.syllabusStatus === Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_REQUESTED) {
            const myArr = _eachSyllabus.candidateDetails.reduce((_acc: any, eachElem: any) => {
                if (newCandidateList.length) {
                    const currentIndex = newCandidateList.findIndex((eachItem: any) => eachItem.candidateUserNumber === eachElem.candidateUserNumber)

                    if (currentIndex === -1) {
                        eachElem.isDuplicate = false;
                        newCandidateList = [...newCandidateList, eachElem]
                    } else {
                        newCandidateList[currentIndex].setNumber = 1;
                        newCandidateList[currentIndex].isDuplicate = true;
                    }
                } else {
                    eachElem.isDuplicate = false;
                    newCandidateList = [...newCandidateList, eachElem]
                }
                return newCandidateList
            }, newCandidateList);
            _eachSyllabus.candidateDetails = [...myArr]
            return _eachSyllabus;
        } else {
            return _eachSyllabus
        }
    })
}

const setContextData = (state = initialState, action: any) => {
    const newState = { ...state }
    switch (action.type) {
        case GET_CONTEXT_DATA_SUCCESS:
            const syllabusList = restructureSyllabusDetails(action.payload, action.syllabusStatusres);
            // remove this code, just to test second sample
            // syllabusList[0].syllabusStatus = Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_REQUESTED
            // syllabusList[0].syllabusStatus = Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.FIRST_SAMPLE_REQUESTED
            return {
                ...newState,
                syllabusList: filterDuplicateCandidatesForSecondEvidence([...syllabusList])
                // syllabusList: restructureSyllabusDetails(action.payload, action.syllabusStatusres)
            }

        case GET_CANDIDATE_LIST:
            const selectedIndex = newState.syllabusList.findIndex((each: any) => each.syllabusNumber === action.syllabusId);
            return {
                ...newState,
                selectedSyllabus: newState.syllabusList[selectedIndex]
            }
        case SET_CURRENT_CENTRE_ID:
            return {
                ...newState,
                currentCentreId: action.payload
            }
        case SET_CURRENT_USER_ID:
            return {
                ...newState,
                currentUserId: action.payload
            }
        case SET_CANDIDATE_IDENTIFIER:
            return {
                ...newState,
                candidateIdentifier: action.candidateIdentifier
            }
        case TOGGLE_ALERT: {
            return {
                ...newState,
                alertData: { ...action.payload.alertInfo }
            };
        }

        case FETCH_CANDIDATE_DOCUMENTS_SUCCESS: {
            return {
                ...newState,
                candidateGenericDocuments: action.payload
            }
        }

        case FETCH_EVIDENCE_FILES_FAILURE: {
            const selectedCandidateWithEvidence = _.cloneDeep(newState.selectedCandidateWithEvidence);
            if ((selectedCandidateWithEvidence as any).evidenceList) {
                (selectedCandidateWithEvidence as any).evidenceList = [];
            }

            return {
                ...newState,
                selectedCandidateWithEvidence: {}
            }
        }

        case UPDATE_RATIONAL_DOCUMENT: {
            let candidateRationalDocument = { ...newState.candidateGenericDocuments };

            candidateRationalDocument = ((newState.selectedSyllabus as any).syllabusStatus === Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_REQUESTED)
                ? { ...candidateRationalDocument, secondrationaledocumentname: action.payload }
                : { ...candidateRationalDocument, rationaledocumentname: action.payload }

            return {
                ...newState,
                candidateGenericDocuments: candidateRationalDocument
                // candidateGenericDocuments: {
                //     ...newState.candidateGenericDocuments,
                //     rationaledocumentname: action.payload
            }
        }

        case FETCH_ADDITIONAL_DOCUMENT_SUCCESS: {
            const testArr = action.payload.map((each: any) => each['additonal-materials']);
            return {
                ...newState,
                candidateAdditionalDocuments: action.payload.map((each: any) => each['additonal-materials']['file-name']),
                additionalDocReadOnlyStatus: action.payload.map((each: any) => each['additonal-materials'])
            }
        }

        case UPDATE_ADDITIONAL_DOCUMENT: {
            return {
                ...newState,
                candidateAdditionalDocuments: action.payload
            }
        }

        case UPDATE_LOADER_SHOW_STATUS: {
            return {
                ...newState,
                isShowLoader: action.payload
            }
        }

        case FETCH_CANDIDATE_FOLDERS_SUCCESS: {
            const selectedCandidate = action.basicStructForEvidence;
            return {
                ...newState,
                selectedCandidateWithEvidence: { ...appendEvidenceData(selectedCandidate, action.payload) }
            }
        }

        case UPDATE_CANDIDATE_GRADE_SUCCESS:
            const index = (newState.selectedCandidateWithEvidence as any).evidenceList.findIndex((res: { [x: string]: any; }) => res['candidate-evidence-file-location'] === action.payload['file-location']);
            const newEvidence = { ...newState.selectedCandidateWithEvidence };
            (newEvidence as any).evidenceList[index]['candidate-evidence-grade'] = action.payload.grade
            return {
                ...newState,
                selectedCandidateWithEvidence: newEvidence
            }

        case UPDATE_CANDIDATE_EVIDENCE_UPLOADED_DELETED_FILE: {
            return {
                ...newState,
                selectedCandidateWithEvidence: updateCandidateEvidence(newState, action.payload)
            }
        }

        case EVIDENCE_SUBMISSION_SUCCESS: {
            const syllabusList = [...newState.syllabusList],
                selectedSyllabus = { ...newState.selectedSyllabus },
                selectedSyllabusNumber = selectedSyllabus.syllabusNumber,
                syllabusListObjectIndex = syllabusList.findIndex(each => each.syllabusNumber === selectedSyllabusNumber);
            syllabusList[syllabusListObjectIndex] = updateSyllabusStatus(syllabusList[syllabusListObjectIndex], action.status)

            return {
                ...newState,
                selectedSyllabus: updateSyllabusStatus(selectedSyllabus, action.status),
                syllabusList: syllabusList
            }
        }

        default: {
            return state;
        }
    }
};

export default setContextData