import React, { FC, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { connect } from 'react-redux';
import { faFolder } from '@fortawesome/free-regular-svg-icons';

import APIService from './../../services/apiService';
import {
    Col,
    Row,
    CardBody,
    Card
} from 'reactstrap';
import Uploader from './../Shared/Uploader/uploader';
import { Constant } from './../../config/constant';
import { updateCandidateGrades, updateCandidateEvidenceDocument, toggleAlertShowStatus, fetchCandidateFolderLocations } from './../../redux-building-blocks/actions';
import { getCurrentSyllabusStayus, checkEvidenceStatusForSecondEvidence } from './../../redux-building-blocks/selectors/util-selector';

interface EvidenceProps {
    eachEvidenceItem: any;
    candidateSetNumber: any;
    index: number;
    isDuplicate: boolean;
}


const CandidateEvidenceDocuments: FC<EvidenceProps> = (props: any): JSX.Element => {
    const [uploadedFileList, setUploadedFileList] = useState<Array<string>>([]);
    const [evidenceData, setEvidenceData] = useState({
        evidenceName: '',
        'candidate-evidence-grade': '',
        evidenceGradeDetails: [],
    });

    const [uploaderProps, setUploaderProps] = useState({
        noOfMaxFile: 10,
        fileFormatAndSizeSupported: Constant.ADDITIONAL_DOCUMENT_ALLOWED_FILE_TYPE_SIZE
    });

    useEffect(() => {
        // if (JSON.stringify()) {
        if (!_.isEqual(props.eachEvidenceItem, evidenceData)) {
            setEvidenceData(props.eachEvidenceItem);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.eachEvidenceItem]);

    const fetchPresignedUploadURl = (filename: string) => {
        const payload = {
            filename,
            filelocation: props.eachEvidenceItem['candidate-evidence-file-location']
        }
        return new Promise((resolve, reject) => {
            APIService.getPreSignedURLForCandidateEvidence(payload).then((response: any) => {
                resolve(response);
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    const handleUploadOrDeleteSuccess = (currentRelatedFiles: Array<string>, type: string, fileName = '') => {
        setUploadedFileList(currentRelatedFiles);
        props.updateCandidateEvidenceDocument(props.index, currentRelatedFiles);
        const message = (type === 'UPLOAD') ? `The file ${fileName} will be scanned for Virus and only accepted if virus-free, else the file will be discarded.` :
            `${fileName} is deleted`;
        props.toggleAlertShowStatus({
            isShowAlert: true,
            alertColor: 'success',
            alertMessage: message
        });

        // Making this call to refresh file list as virus scan may discard uploaded file
        if (type === 'UPLOAD') {
            const candidatePayload = {
                candidateId: props.candidateId,
                syllabusId: props.syllabusId
            }
            setTimeout(() => {
                props.fetchCandidateFolderLocations(candidatePayload);
            }, Constant.GET_CALL_TIME_AFTER_UPLOAD);
        }
    }

    const handleUploadOrDeleteError = (currentRelatedFiles: Array<string>, type: string) => {
        const message = (type === 'UPLOAD') ? `You can't upload ${currentRelatedFiles}, Due to network issue` :
            `You can't delete ${currentRelatedFiles}, Due to network issue`;
        props.toggleAlertShowStatus({
            isShowAlert: true,
            alertColor: 'danger',
            alertMessage: message
        })
    }

    const handleFileDelete = (filename: string) => {
        return new Promise((resolve, reject) => {
            APIService.deleteCandidateEvidenceDoc(props.eachEvidenceItem['candidate-evidence-file-location'], filename).then((response: any) => {
                resolve(response);
            }).catch((error: any) => {
                reject(error);
            });
        });
    }

    useEffect(() => {
        if (!_.isEqual(props.eachEvidenceItem?.uploadedProps?.uploadedFiles, uploadedFileList)) {
            const docs = _.isArray(props.eachEvidenceItem?.uploadedProps?.uploadedFiles) ? props.eachEvidenceItem?.uploadedProps?.uploadedFiles : [];
            setUploadedFileList(docs);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.eachEvidenceItem?.uploadedProps?.uploadedFiles]);


    useEffect(() => {
        setUploaderProps((prev) => ({ ...prev, fileList: uploadedFileList }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedFileList]);



    const gradeOptionChange = (e: any, evidence: any, index: number) => {
        const gradePayload = {
            candidate: props.candidateId,
            "file-location": evidence['candidate-evidence-file-location'],
            grade: e.target.value,
            syllabus: props.syllabusId
        };
        // let myEvidence = { ...evidence };
        let myEvidence = _.cloneDeep(evidence);
        myEvidence['candidate-evidence-grade'] = e.target.value;
        setEvidenceData(myEvidence)
        props.updateCandidateGrades(gradePayload)
    }

    return (
        <React.Fragment>
            <Card key={props.index}>
                <CardBody className="card-body pxy-2">
                    <Row className="align-items-center">
                        <Col md={8}>
                            {/* <div className="pb-4"> <FontAwesomeIcon className="fileUpload" icon={faFolder} /><span className="pl-2 evidenceLabel"> Evidence {props.index + 1} </span></div> */}
                            <div className="pb-4 text-capitalize"> <FontAwesomeIcon className="fileUpload" icon={faFolder} /><span className="pl-2 evidenceLabel">{evidenceData['evidenceName']} </span></div>
                        </Col>
                    </Row>
                    <br></br>
                    <Row className="align-items-center">
                        <Col md={8}>
                            <select className="evidence-dropdown"  value={evidenceData['candidate-evidence-grade'] ? evidenceData['candidate-evidence-grade'] : ""} onChange={(e) => gradeOptionChange(e, evidenceData, props.index)} disabled={props.isSubmited || (props.syllabusStatus === Constant.SYLLABUS_STATUS_MESSAGE_FROM_API.SECOND_SAMPLE_REQUESTED && props.candidateSetNumber === 1 && !props.isDuplicate)}>
                            {/* <select className="evidence-dropdown" value={evidenceData['candidate-evidence-grade'] ? evidenceData['candidate-evidence-grade'] : ""} onChange={(e) => gradeOptionChange(e, evidenceData, props.index)} disabled={props.isSubmited}> */}
                                <option value="">Select a grade</option>
                                {evidenceData && evidenceData.evidenceGradeDetails && evidenceData.evidenceGradeDetails.map((e: string, key: number) => {
                                    return <option key={key} value={e}>{e}</option>
                                })}
                            </select>
                        </Col>
                    </Row>
                    <br></br>
                    <Row className="align-items-center">
                        <Col md={8}>
                            <Uploader key={`Uploader-${props.index}-${+ new Date()}`} index={props.index} {...uploaderProps}
                                fileListWithStatus={(evidenceData as any)?.uploadedProps?.fileListWithStatus}
                                successCallback={handleUploadOrDeleteSuccess}
                                getPreSignedUploadURl={fetchPresignedUploadURl}
                                errorCallback={handleUploadOrDeleteError}
                                fileDeleteHandler={handleFileDelete} />
                        </Col>
                    </Row>
                    <br></br>
                </CardBody>
            </Card>
        </React.Fragment>)
}

const mapStateToProps = (state: any) => {
    return {
        syllabusId: state.setContextData.selectedSyllabus?.syllabusNumber,
        candidateId: state.setContextData?.candidateIdentifier,
        syllabusStatus: state.setContextData?.selectedSyllabus?.syllabusStatus,
        isSubmited: getCurrentSyllabusStayus(state)
    }
}

const mapDispatchToProps = {
    updateCandidateGrades,
    updateCandidateEvidenceDocument,
    toggleAlertShowStatus,
    fetchCandidateFolderLocations
}
export default connect(mapStateToProps, mapDispatchToProps)(CandidateEvidenceDocuments);